import React from 'react';
import LayoutSecondary from '../components/layout/LayoutSecondary';

const NotFound = () => (
  <LayoutSecondary>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex text-sm">
        <h1 className="blocktext-4xl lg:text-5xl xl:text-6xl font-bold leading-none w-full">
          Not found!?
        </h1>
        <p>
          We may have moved things around a bit since last time... 
        </p>
      </div>
    </section>
  </LayoutSecondary>
);

export default NotFound;


//
//
// import React from 'react';
// import Layout from '../components/layout/Layout';
//
// const NotFound = () => (
//   <Layout>
//     <h1>Not Found</h1>
//     <p>You just hit a page that doesn&#39;t exist...</p>
//   </Layout>
// );
//
// export default NotFound;
